import { ApplicationState } from '../../../../stores';
import { connect, useDispatch, useSelector } from 'react-redux';

// styles
import './OrdenFabricacionEnCursoCardComponent.css';

// store
import * as OrdenesFabricacionStore from '../../../../stores/OrdenFabricacion';
import { useEffect } from 'react';
import { Orden } from '../../../../model/orden';
import { useHistory } from 'react-router-dom';
import ordenesFabricacion from '../../../../pages/ordenesFabricacion';
import Moment from 'react-moment';
import moment from 'moment';

export interface IProps {
  ordenes: Orden[];
}

export const OdenFabricacionEnCursoCards = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const SelectOrderHandler = (order: Orden) => {
    history.push('ordenes/info/' + order.id);
  };

  useEffect(() => {    
    //dispatch(mapDispatchToProps.getAllOrdenes(1, 300, false, '', '', '' ));
  }, []);

  return props.ordenes ? (
    <>
      <div className="OrdenCardsList">
        {props.ordenes.map((orden: Orden) => (
          <div
            className="OrdenEnCursoCard"
            onClick={() => SelectOrderHandler(orden)}
          >
            <small>Lote</small>
            <h1>{orden.lote}</h1>
            <small>Fecha solicitud</small>
            <h1>
              {moment(orden.fechaorden).add(-3, 'h').format('DD/MM/YYYY HH:mm')}
            </h1>
            <small>Producto</small>
            <h2>
              <strong>{orden.producto?.codigo}</strong>
            </h2>
            <h2>{orden.producto?.nombre}</h2>
            <small>Cliente</small>
            <h2>{orden?.codigoCliente}</h2>
            <div>
              <small>Cantidad</small>
              <label>{orden.cantidad} kg</label>
            </div>
          </div>
        ))}
        {props.ordenes.length === 0 && (
          <>
            <label style={{ marginLeft: 20 }}>
              No hay ordenes de fabricación en curso
            </label>
          </>
        )}
      </div>
    </>
  ) : (
    <>loading</>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.ordenes
});

const mapDispatchToProps = {
  ...OrdenesFabricacionStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OdenFabricacionEnCursoCards as any);
