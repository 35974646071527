import { ApplicationState } from '../../../../stores';
import { connect, useDispatch, useSelector } from 'react-redux';

// styles
import './OrdenFabricacionEnCursoListComponent.css';

// store
import * as OrdenesFabricacionStore from '../../../../stores/OrdenFabricacion';
import { useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { OrdenFabricacionEnCursoItem } from './OrdenFabricacionEnCursoItemComponent';
import ordenesFabricacion from '../../../../pages/ordenesFabricacion';
import { Orden } from '../../../../model/orden';

export const OdenFabricacionEnCursoList = () => {
  const dispatch = useDispatch();
  const OrdenesFabricacionState = useSelector(
    (state: ApplicationState) => state.ordenes
  );

  useEffect(() => {
    dispatch(mapDispatchToProps.getAllOrdenes(1, 5000, false));
  }, []);

  return (
    <div className="OrdenesWidgetCard">
      <label className="SubContentTitle">Ordenes de fabricación en curso</label>
      <ListGroup>
        {OrdenesFabricacionState?.ordenes
          ?.filter((order: Orden) => (!order.finalizado ? true : false))
          .map((pendingOrder: Orden) => (
            <ListGroup.Item>
              <OrdenFabricacionEnCursoItem order={pendingOrder} />
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.ordenes
});

const mapDispatchToProps = {
  ...OrdenesFabricacionStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OdenFabricacionEnCursoList as any);
