import { MateriaPrima, NewMateriaPrima } from '../model/materia_prima';
import { IResult } from '../model/Result';
import { AppThunkAction } from './';

//Actions
import * as Actions from '../redux/Actions/SuppliesActions';

// Functions
import * as Functions from '../redux/Functions/Commons';

// services
import * as Services from '../services/SuppliesServices';
import { ErrorType } from '../model/FetchError';
import { SuppliesState } from '../redux/States/SuppliesState';

type KnownAction =
  | Actions.FailAddSupply
  | Actions.FailGetAllSupplies
  | Actions.FailGetSupply
  | Actions.FailSaveSupply
  | Actions.ReceiveAddedSupply
  | Actions.ReceiveAllSupplies
  | Actions.ReceiveSavedSupply
  | Actions.ReceiveSupply
  | Actions.RequestAddSupply
  | Actions.RequestAllSupplies
  | Actions.ReceiveSavedSupply
  | Actions.ReceiveSupply
  | Actions.RequestAddSupply
  | Actions.RequestAllSupplies
  | Actions.RequestSaveSupply
  | Actions.RequestSupply
  | Actions.SetSelectedSupply
  | Actions.UnsetSelectedSupply
  | Actions.SetSupplyNewStockAmount
  | Actions.RequestAllLowStockSupplies
  | Actions.ReceiveAllLowStockSupplies
  | Actions.FailGetAllLowStockSupplies
  | Actions.RequestDeleteSupply
  | Actions.ReceiveDeletedSupply
  | Actions.FailDeleteSupply;

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/materiasprimas';

export const actionCreators = {
  GetAllSupplies:
    (
      pageNumber?: number,
      pageSize?: number,
      searchText?: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_ALL_SUPPLIES', filterCriteria: searchText });

      let params: URLSearchParams = Functions.GetQueryStringParams(
        pageNumber,
        pageSize,
        searchText
      );

      let RequestURL: string = BaseURL;

      if (params) RequestURL += '?' + params.toString();

      await fetch(RequestURL)
        .then((response) => {
          console.log(response);
          if (response.ok) {
            return response.json() as Promise<IResult<MateriaPrima[]>>;
          } else {
            throw response;
          }
        })
        .then((data: IResult<MateriaPrima[]>) => {
          dispatch({
            type: 'RECEIVE_ALL_SUPPLIES',
            isFiltered: searchText ? true : false,
            filterCriteria: searchText,
            supplies: data.data
          });
        })
        .catch((error: any) => {
          console.log(error);
          if (typeof error.text === 'function') {
            error.text().then((body: any) => {
              dispatch({
                type: 'FAIL_GET_ALL_SUPPLIES',
                Error: Functions.HttpErrorHandler(body, error)
              });
            });
          } else {
            console.log(error);
          }
        });
    },
  GetSupply:
    (supplyId: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_SUPPLY', Id: supplyId });

      await fetch(BaseURL + '/' + supplyId)
        .then((response) => {
          if (response.ok) {
            return response.json() as Promise<IResult<MateriaPrima>>;
          } else {
            throw response;
          }
        })
        .then((data: IResult<MateriaPrima>) => {
          if (data.statusCode >= 400) {
            throw data;
          } else {
            dispatch({
              type: 'RECEIVE_SUPPLY',
              supply: data.data
            });
          }
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_GET_SUPPLY',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    },
  SetSelectedSupply:
    (supply: MateriaPrima): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'SET_SELECTED_SUPPLY', selectedSupply: supply });
    },
  SetSelectedSupplyByID:
    (SupplyId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const SupplyFound = getState().Supplies?.Supplies?.find(
        (supply: MateriaPrima) => (supply.id === SupplyId ? true : false)
      );
      if (SupplyFound) {
        dispatch({
          type: 'SET_SELECTED_SUPPLY',
          selectedSupply: SupplyFound
        });
      }
    },
  UnsetSelectedSupply: (): AppThunkAction<KnownAction> => async (dispatch) => {
    dispatch({ type: 'UNSET_SELECTED_SUPPLY' });
  },
  AddSupply:
    (supply: NewMateriaPrima): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      if (typeof supply.capacidad !== 'number')
        supply.capacidad = Number(supply.capacidad);
      if (supply.esEnvase && supply.capacidad === 0) supply.capacidad = null;
      if (!supply.esEnvase) {
        supply.capacidad = null;
        supply.unidadDeMedidaCapacidadId = null;
      }
      if (typeof supply.minimoStockAceptable !== 'number')
        supply.minimoStockAceptable = Number(supply.minimoStockAceptable);

      if (supply.minimoStockAceptable === 0) supply.minimoStockAceptable = null;

      dispatch({ type: 'REQUEST_ADD_SUPPLY', newSupply: supply });

      // Add Presentation

      await fetch(BaseURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(supply)
      })
        .then((fetchResponse: any) => {
          if (!fetchResponse.ok) {
            throw fetchResponse;
          } else {
            return fetchResponse.json() as IResult<MateriaPrima>;
          }
        })
        .then((data: IResult<MateriaPrima>) => {
          dispatch({ type: 'RECEIVE_ADDED_SUPPLY', newSupply: data.data });
        })
        .catch((error: any) =>
          error.text().then((body: any) =>
            dispatch({
              type: 'FAIL_ADD_SUPPLY',
              Error: Functions.HttpErrorHandler(body, error)
            })
          )
        );
    },
  SaveSupply:
    (supply: MateriaPrima): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      if (typeof supply.capacidad !== 'number')
        supply.capacidad = Number(supply.capacidad);
      if (supply.esEnvase && supply.capacidad === 0) supply.capacidad = null;
      if (!supply.esEnvase) {
        supply.capacidad = null;
        supply.unidadDeMedidaCapacidadId = null;
      }
      if (typeof supply.minimoStockAceptable !== 'number')
        supply.minimoStockAceptable = Number(supply.minimoStockAceptable);

      if (supply.minimoStockAceptable === 0) supply.minimoStockAceptable = null;

      dispatch({ type: 'REQUEST_SAVE_SUPPLY', supply: supply });

      await fetch(BaseURL + '/' + supply.id, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify(supply)
      })
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<MateriaPrima>>;
          }
        })
        .then((data: IResult<MateriaPrima>) => {
          if (data.statusCode >= 400) {
            throw data;
          } else {
            dispatch({ type: 'RECEIVE_SAVED_SUPPLY', savedSupply: data.data });
          }
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_SAVE_SUPPLY',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    },
  SetSupplyNewStockAmount:
    (supplyId: number, newAmount: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'SET_SUPPLY_NEW_STOCK_AMOUNT',
        SupplyId: supplyId,
        NewAmount: newAmount
      });
    },
  DeleteSupply:
    (supplyId: number, token: string): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_DELETE_SUPPLY', SupplyId: supplyId });
      Services.DeleteSupply(supplyId, token)
        .then((result: boolean) => {
          dispatch({ type: 'RECEIVE_DELETED_SUPPLY', SupplyId: supplyId });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'FAIL_DELETE_SUPPLY',
            Error: error
          })
        );
    },
  GetLowStockSupplies: (token: string): AppThunkAction<KnownAction> => async (dispatch) => {
    dispatch({ type: 'REQUEST_ALL_LOW_STOCK_SUPPLIES' });

    Services.GetLowStockSupplies(token)
      .then((result: MateriaPrima[]) => {
        dispatch({ type: 'RECEIVE_ALL_LOW_STOCK_SUPPLIES', supplies: result });
      })
      .catch((error: ErrorType) =>
        dispatch({
          type: 'FAIL_GET_ALL_LOW_STOCK_SUPPLIES',
          Error: error
        })
      );
  }
};
